// The file is mostly taken from http://codepen.io/collection/oeBwH/2/, with some minor, custom improvements
// The code works on the range-slider library
function RangeInput (el) {
      this.el = el;
      this._handleEnd = this._handleEnd.bind(this);
      this._handleStart = this._handleStart.bind(this);
      this._handleInput = this._handleInput.bind(this);

      this.end = 'change';
      this.start = 'ontouchstart' in document ? 'touchstart' : 'mousedown';
      this.input = 'input';
      this.maxRotation = 10;
      this.softenFactor = 3;

      var $xAxis = $('<div class="rangeslider-x-axis"/>');
    //   var $xAxis = $('<div class="rangeslider__ruler"/>');


      //Call the rangeslider plugin
      $('input[type=range]').val(0).rangeslider({
          polyfill: false, //Never use the native polyfill
          rangeClass: 'rangeslider',
          disabledClass: 'rangeslider-disabled',
          horizontalClass: 'rangeslider-horizontal',
          verticalClass: 'rangeslider-vertical',
          fillClass: 'rangeslider-fill-lower',
          handleClass: 'rangeslider-thumb',
          
          onInit: function() {
            //No args are passed, so we can't change context of this
            var pluginInstance = this;
            //Move the range-output inside the handle so we can do all the stuff in css
            $(pluginInstance.$element)
              .parents('.range')
              .find('.range-output')
              .appendTo(pluginInstance.$handle);
                // Make x axis labels; Code differs from source
                var labelStep = this.$element.data('label-step') || 5;
                this.$range.prepend($xAxis);
                makeXAxis.call(this, $xAxis, labelStep);
                $(window).resize(function () {
                    labelStep = this.$element.data('label-step') || 5;
                    $xAxis.empty();
                    makeXAxis.call(this, $xAxis, labelStep);
                }.bind(this));
           }
       });
       // custom function 
       function makeXAxis ($el, step) {
           var $el = $($el),
               $label,
               // for some reason, probably padding, need to use css width property for the handle rather general width
               handleWidth = parseInt(this.$handle.css('width').slice(0,-2)),
               // scale is the slider range minus the button
               scale = this.$range.width() - handleWidth,
               value = this.min;
           
           while (value <= this.max) {
               //return the labels position relative to the graph
               var cssPos = getGraphPosition(value, this.min, this.max, scale);
               $label = $('<div class="rangeslider-x-axis-label">'+value+'</div>');
               $label.css({'left': cssPos + 'px'});
               $el.append($label);
               $label.css({'margin-left': ((handleWidth / 2) - ($label.width() / 2)) + 'px'});
               value += step;
           }
       }
       
       function getGraphPosition (position, min, max, scale) {
           var percentage = (position - min)/(max - min);
           var pos = (!Number.isNaN(percentage)) ? percentage * scale : 0;
           return pos;
       }
       
      this.sliderThumbEl = el.querySelector('.rangeslider-thumb');
      this.outputEl = el.querySelector('.range-output');
      this.inputEl = el.querySelector('input[type=range]');
      this._lastOffsetLeft = 0;
      this._lastTimeStamp = 0;

      this.el.querySelector('.rangeslider').addEventListener(this.start, this._handleStart);
};

RangeInput.prototype._handleStart = function (e) {
    this._lastTimeStamp = new Date().getTime();
    this._lastOffsetLeft = this.sliderThumbEl.offsetLeft;
    //Wrap in raf because offsetLeft is updated by the plugin after this fires
    requestAnimationFrame(function () {
    //Bind through jquery because plugin doesn't fire native event
        $(this.inputEl).on(this.input, this._handleInput);
        $(this.inputEl).on(this.end, this._handleEnd);
    }.bind(this));
};

RangeInput.prototype._handleEnd = function (e) {
    //Unbind through jquery because plugin doesn't fire native event
    $(this.inputEl).off(this.input, this._handleInput);
    $(this.inputEl).off(this.end, this._handleEnd);
    requestAnimationFrame(function () {
       this.outputEl.style.transform = 'rotate(0deg)';
   }.bind(this))
};

RangeInput.prototype._handleInput = function (e) {
      var now = new Date().getTime();
      var timeElapsed = now - this._lastTimeStamp || 1;
      var distance = this.sliderThumbEl.offsetLeft - this._lastOffsetLeft;
      var direction = distance < 0 ? -1 : 1;
      var velocity = Math.abs(distance) / timeElapsed; //pixels / millisecond
      var targetRotation = Math.min(Math.abs(distance * velocity) * this.softenFactor, this.maxRotation);

      requestAnimationFrame(function () { 
          this.outputEl.style.transform = 'rotate(' + targetRotation * -direction + 'deg)'
      }.bind(this));

      this._lastTimeStamp = now;
      this._lastOffsetLeft = this.sliderThumbEl.offsetLeft;
};
