$.get("/ajax/get_sites", function (result) {
    if (typeof result === 'object') {
        var data = result;
        $.each(data, function (index, sport) {
            var option = "";

            // Make first letter of each word uppercase
            var UCFirstSportName = sport.sport_name.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });

            /**
             * Store use sport and gender JSON as value. This will be decoded on the backend.
             * See signup.php@submit_step_1
             */
            if (sport.is_single_gender == 1) {
                option += `<option value='${JSON.stringify({
                    site_id: sport.id,
                    gender: sport.primary_gender
                })}' data-gender='${sport.primary_gender} '>${(sport.primary_gender == 1) ? "Men's" : "Women's"} ${UCFirstSportName}</option>`;
            } else {
                option += `<option value='${JSON.stringify({
                    site_id: sport.id,
                    gender: "1"
                })}' data-gender='1'>Men's ${UCFirstSportName}</option>`;
                option += `<option value='${JSON.stringify({
                    site_id: sport.id,
                    gender: "2"
                })}' data-gender='2'>Women's ${UCFirstSportName}</option>`;
            }

            $("#signup-form-site-id").append(option);
        });
    }
});