var timeout;

$('#quick-college-login-form .college-login-reset-password').click(function (e) {
    e.preventDefault()
    var $form = $(this).closest('#quick-college-login-form');
    var email = $form.find('[name=email]').val();

    $.ajax({
        url: '/ajaxs/reset_password',
        type: 'POST',
        dataType: 'json',
        data: { email: email },
        success: function (response, textStatus, xhr) {
            if (response.status === 'success') {
                showSuccess($form, response.message, response.persist);
            } else {
                var message = response.message || 'An unexpected error occurred. Please try again later.';
                showError($form, message, response.persist);
            }
        },
        error: function (xhr, textStatus, error) {
            showError($form, 'An unexpected error occurred. Please try again later.', false);
        }
    });
});

$("#quick-college-login-form .college-login-coach-set-password").click(function (e) {
    e.preventDefault()
    var $form = $(this).closest('#quick-college-login-form');
    var email = $form.find('[name=email]').val()

    $.ajax({
        url: '/ajaxs/coach_set_password',
        type: 'POST',
        dataType: 'json',
        data: { email: email },
        success: function (response, textStatus, xhr) {
            if (response.status === 'success') {
                showSuccess($form, response.message, response.persist);
            } else {
                var message = response.message || 'An unexpected error occurred. Please try again later.';
                showError($form, message, response.persist);
            }
        },
        error: function (xhr, textStatus, error) {
            showError($form, 'An unexpected error occurred. Please try again later.', false);
        }
    });
});

$("#quick-college-login-form").on('submit', function (event) {
    event.preventDefault();

    var formData = {
        "email": $(this).find("input[name=email]").val(),
        "password": ($(this).find("input[name=password]").val().trim() != '') ? $(this).find("input[name=password]").val() : null
    };

    var that = this;

    $.ajax({
        url: '/ajax/coach_login',
        type: 'POST',
        data: formData,
        success: function (responseString) {
            var response = JSON.parse(responseString);
            $(that).find("input[name=password]").attr('placeholder', 'Password');
            $(that).find("input[name=password]").prop('disabled', true);
            $(that).find("input[name=email]").prop('disabled', false);
            $(that).find(".email-edit-button").hide();
            clearTimeout(timeout);
            if (response.status == 'success') {
                showSuccess(that, response.message, response.persist);
            } else if (response.status == 'error') {
                showError(that, response.message, response.persist);
            } else if (response.status == 'enter_password') {
                $(that).find(".email-edit-button").show();
                $(that).find("input[name=email]").prop('disabled', true);
                $(that).find("#get-started-button").hide();
                $(that).find("#message").hide();
                $(that).find("#enter_password").slideDown();
                $(that).attr('class', 'enter_password');
                $(that).find("input[name=password]").prop('disabled', false);
            } else if (response.status == 'incorrect_password') {
                $(that).find(".email-edit-button").show();
                $(that).find("input[name=email]").prop('disabled', true);
                $(that).find("#get-started-button").hide();
                $(that).find("#message").hide();
                $(that).find("#enter_password").slideDown();
                $(that).attr('class', 'incorrect_password');
                $(that).find("input[name=password]").attr('placeholder', 'Incorrect Password');
                $(that).find("input[name=password]").val('');
                $(that).find("input[name=password]").prop('disabled', false);
            } else if (response.status == 'logged_in') {
                // Redirect path will either be /home for a single user
                // Or /login for a user who needs disambiguation
                window.location.href = response.redirect_path;
            }

        },
        error: function (jqXhr, textStatus, errorThrown) {
            console.log(errorThrown);
        }
    });
});

$(".email-edit-button").click(function (e) {
    e.preventDefault();
    resetState($("#quick-college-login-form"), 0);
})

function showSuccess(form, message, persist) {
    $(form).find(".email-edit-button").hide();
    $(form).find("input[name=email]").prop('disabled', false);
    $(form).find("#get-started-button").show();
    $(form).find("#enter_password").hide();
    $(form).find("#message").html(message);
    $(form).find("#message").slideDown();
    $(form).attr('class', 'success');

    if (persist) {
        $(form).find('input, button').prop('disabled', true);
    } else {
        resetState(form);
    }
}

function showError(form, message, persist) {
    $(form).find(".email-edit-button").hide();
    $(form).find("input[name=email]").prop('disabled', false);
    $(form).find("#get-started-button").show();
    $(form).find("#enter_password").hide();
    $(form).find("#message").html(message);
    $(form).find("#message").slideDown();
    $(form).attr('class', 'error');
    if (!persist) {
        resetState(form);
    }
}

function resetState(that, delay = 7000) {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
        $(that).find(".email-edit-button").hide();
        $(that).find("input[name=email]").prop('disabled', false);
        $(that).find("input[name=password]").val('');
        $(that).find("input[name=password]").prop('disabled', true);
        $(that).find("#get-started-button").show();
        $(that).find("#message").slideUp();
        $(that).find("#enter_password").slideUp();
        $(that).attr('class', '');
    }, delay);
}