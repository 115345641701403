$(document).ready(function () {
    var SROTG = {
        init : function () {
            functions = {
                'default': SROTG.default,
                'home': SROTG.home,
                'login': SROTG.handleLoginForm,
                'pricingCards': SROTG.handleFlippingPricingCards,
                'pricingClub': SROTG.handlePricingClub,
                'eventsPage': SROTG.handleEventsPage,
                'clubsPage': SROTG.handleClubsPage,
                'highlightReels': SROTG.handleHighlightReels,
                'campGuide': SROTG.handleCampGuide,
                // 'redirectModal': SROTG.showRedirectModal,
                'commits': SROTG.handleCommits,
                'otgSubmenus' : SROTG.handleSubmenu,
            };

            activationElements = {
                'default' : ['body.sr_otg'],
                'home': ['body.home'],
                'login': ['body.login_form'],
                'pricingCards': ['body.player_pricing'],
                'pricingClub': ['body.club_pricing'],
                'eventsPage': ['body.events'],
                'clubsPage': ['body.clubs'],
                'highlightReels': ['body.highlight_reels'],
                'campGuide': ['body.camp_guide'],
                'commits': ['body.athletes_commit'],
                'otgSubmenus': ['.MobileNav-wrap'],
            };

            for (var functionName in activationElements) {
                // iterate over activation elements
                for (var i = 0; i < activationElements[functionName].length; i++) {
                    // if element is on page (usually on the body tag, call its function)
                    if ($(activationElements[functionName][i]).length) {
                        functions[functionName].call(this); break;
                    }
                }
            }
        },

        handleSubmenu : function () {
            $('div.MobileNav-wrap ul.MobileNav-links li a').on('click',function () {
                $(this).children().toggleClass('fa-chevron-down fa-chevron-up');
            })
        },

        default : function () {
            this.handleFixedNav();
            // Smooth scrolls for bouncing arrow and desktop demo button bouncing arrow is clicked
            $('.js-scrollTo').click(function (e) {
                e.preventDefault();
                if ($(this.hash).length) {
                    $('html, body').animate({
                        scrollTop: $(this.hash).offset().top
                    }, 1000);
                }
            });
            // show success modal if demo form is submitted
            if (window.location.hash === "#interest_submitted") {
                    swal({title: "Thanks!",
                        type: 'success',
                        text: 'We\'ve got your info and will be in touch shortly. <br> In the meantime, you should <a href="/athletes">checkout our solutions page!</a>',
                        timer: 45000,
                        showCloseButton: true,
                        showConfirmButton: false,
                    });
                setTimeout(function () {
                    $('.sweet-close').html('').addClass('fa fa-times fa-2');
                }, 100)
            window.location.hash = '';
            }
            // demo modal forms
            $('.schedule-demo-modal').on('click', function(e) {
                e.preventDefault();
                if ($(".MobileNav-overlay").css('display') === 'block') {
                    setTimeout(function () {
                        $(".MobileNav-overlay").hide();
                    }, 600);
                }
                // pathName is to redirect to same page after pardot form is submitted
                // also we replace the slashes in pathname with '---' so that url can be nested indefinitely, e.g. /events/soccer/boys/upcoming  once they're converted back to slashes on the controller.
                var url = this.href + '/' + (window.location.pathname ? window.location.pathname : '').replace(/\//g, '---').slice(3);
                var $link = $(this);

                if ($('#request-demo').length <= 0) {
                    $.get(url, {'no_redirect': true}, function (data) {
                        $('<div class="modal fade" id="request-demo"><div class="modal-dialog multi-user-forms"></div></div>').insertAfter('#header');
                        $('.modal-dialog').html(data).find('.container').removeClass();
                        $('#request-demo').modal();
                        SR.HandleSRDemoModal($link);
                    });
                } else {
                    $('#request-demo').modal();
                }
                // for some reason the bootstrap library we're using is lagging with removing the modal, so remove it manually
                $(document).off('click', '.modal', hideModal);
                $(document).off('click', '#request-demo .close', hideModal);
                $(document).on('click', '.modal', hideModal);
                $(document).on('click', '#request-demo .close', hideModal);
                function hideModal (e) {
                    if (e.target === document.getElementById('request-demo') || e.currentTarget === $('#request-demo .close')[0]) {
                        $('#request-demo').hide();
                    }
                }
            });
        },

        handleFixedNav : function () {
            // Keep track of the window's scroll Y position.
            var scrollPosY;
            // Decide when the nav toggle (visible and not) begins
            var toggleNavPoint = $('.js-ToggleNavLong').length ? 400 : 50;
            // Decide when nav background becomes white or black
            var changeColorPoint = $('.js-ToggleNavLong').length ? 540 : $('.js-ToggleNavShort').length ? 30 : -1;
            // Slide global nav up/down with headroom.js
            $("#header").headroom({
                // let the scroll continue for a while if header is position fixed; if not, remove it pretty quickly
                "offset": toggleNavPoint,
                "tolerance": 2,
            });

            if (changeColorPoint < 0 || window.scrollY > toggleNavPoint) {
                $(".MainNav").addClass("MainNav--active");
                $(".MainNav-logo").addClass("MainNav-logo--redBlack");
                $(".MainNav-logo--athletes").addClass("MainNav-logo--redBlack--athletes");
                $(".MainNav-logo--clubs").addClass("MainNav-logo--redBlack--clubs");
                $(".MainNav-logo--HS").addClass("MainNav-logo--redBlack--HS");
                $(".MainNav-logo--colleges").addClass("MainNav-logo--redBlack--colleges");
            }

            $(window).on('scroll', function () {
                if ($(window).scrollTop() > changeColorPoint) {
                    // Make global nav opaque and shrink
                    //SR NOTE refactor scss when get chance, is not actually 'active', but means that the background is white/ entire thing is more visible over background
                    $(".MainNav").addClass("MainNav--active");
                    $(".MainNav-logo").addClass("MainNav-logo--redBlack");
                    $(".MainNav-logo--athletes").addClass("MainNav-logo--redBlack--athletes");
                    $(".MainNav-logo--clubs").addClass("MainNav-logo--redBlack--clubs");
                    $(".MainNav-logo--HS").addClass("MainNav-logo--redBlack--HS");
                    $(".MainNav-logo--colleges").addClass("MainNav-logo--redBlack--colleges");
                } else {
                    // Make global nav transparent
                    $(".MainNav").removeClass("MainNav--active");
                    $(".MainNav-logo").removeClass("MainNav-logo--redBlack");
                    $(".MainNav-logo--athletes").removeClass("MainNav-logo--redBlack--athletes");
                    $(".MainNav-logo--clubs").removeClass("MainNav-logo--redBlack--clubs");
                    $(".MainNav-logo--HS").removeClass("MainNav-logo--redBlack--HS");
                    $(".MainNav-logo--colleges").removeClass("MainNav-logo--redBlack--colleges");
                };
            });

            // For opening and closing mobile nav
            $(".MobileNav-button--open").on('click',function () {
                // Remember where the window has been scrolled.
                scrollPosY = window.scrollY;
                $(".MobileNav-overlay").fadeToggle(200);
                $(".layout").css('overflow','hidden');
                // set HTML to fixed vs. body.  Weird iOS/Chrome stuff makes this better.
                $("html").css("position", "fixed");
            });

            $('.MobileNav-button--close').on('click', function () {
                $(".MobileNav-overlay").fadeToggle(200);
                $(".layout").css('overflow','scroll');
                // Set the html element back to static.
                $("html").css("position", "static");
                // Fixes issue where when you set the html to fixed, it jumps to the top of the page.
                window.scrollTo(0, scrollPosY);
            });

            // temporarily remove header if moving directly to bottom of page from blog
            if (window.location.hash === '#sr-demos') {
                $('.headroom').removeClass('headroom--pinned').addClass('headroom--unpinned');
            }
        },

        home : function () {
        // cd tabs and carousel section
            $('#playerCarousel').carousel({cycle: true }); // use interval: mil key to set interval
            $('#clubCarousel').carousel({cycle: true });

            if ($('.carousel').length && typeof $.fn.swiperight === 'function') {
                $(".carousel").swiperight(function() {
                    $(this).carousel('prev');
                });
                $(".carousel").swipeleft(function() {
                    $(this).carousel('next');
                });
            };
            // set up tabs
            $(document).on('click', '.cd-tabs-navigation a', function (e) {
                e.preventDefault();
                var $this = $(this);
                if (!$this.hasClass('selected')) {
                    $this.addClass('selected').siblings().removeClass('selected');
                    var $selectedContent = $this.closest('.cd-tabs').find('.TabContent[data-content="'+$this.data('content')+'"]');
                    $selectedContent.addClass('selected').siblings().removeClass('selected');
                    styleCarousel($selectedContent);
                }
            });
            // BS Carousel is a little buggy when switching from display none to block; So we have this quick fix: start it up again
            // by clicking the first and manually setting the indicator
            function styleCarousel ($selectedContent) {
                $($selectedContent.find('.carousel-indicators li')[0]).trigger('click');
                setTimeout(function () {
                    $($selectedContent.find('.carousel-indicators li').removeClass('active')[0]).addClass('active');
                }, 1);
            }
            // Starts count up animation
            $('.counter').counterUp({
                time: 800
            });

            $('.ProductOverviews-tabBtnGroup').waypoint(function(direction) {
                $('.ProductOverviews-artIphone').addClass('slideUp');
                $('.ProductOverviews-artBrowser').addClass('slideLeft');
            }, {offset: '450px', triggerOnce: true});

            $(window).scroll(function() {
                // Fades element in/out on scroll
                var scrollPosition = $(this).scrollTop();
                $('.js_fadeInOutOnScroll').css({'opacity' : 1 - scrollPosition / 520});
                $('.js_fadeInOutOnScroll2').css({'opacity' : 1 - scrollPosition / 300});
            });

            $(document).on('click', '#mobile-demo-button', function () {
                $(".MobileNav-overlay").fadeToggle(200);
            });
        },

        handlePricingClub : function () {
            // sportPricingData is array of price objects, sorted by team count
            var sportPricingData = [], currentPriceRange = {price_per_team: 0},
                teamCount = 0, sportSelected = false, singleUnitChosen = false,
                priceUnits = 'teams', maxUnits = 20, rangeElement;
            // rangeInput is an add on to rangeslider that mostly adds the card animation and and the x-axis
            rangeElement = new RangeInput(document.querySelector('.range'));
            $('#sliderInput').prop('disabled', true).rangeslider('update', true);
            $(document).on('change', '.ClubPricing-dropdown', function (el) {
                if (!sportSelected) {
                    $('#sliderInput').prop('disabled', false).rangeslider('update', true);
                    $('.PriceSliderWrap').css({'opacity': '1'});
                    $('.fadeInPricingInfo').fadeIn({duration: 500});
                }
                $el = $(this).find('.js-dropdown-sport[value="'+this.value+'"]');
                getSportPricingInfo($el.data('site-id'));
            });

            $('#sliderInput').on('input', function () {
                teamCount = this.value;
                updateSportPrices();
            });

            function updateSportPrices () {
                $('.NoPricingMessage').hide();
                $('.PriceSlider').show();

                if (teamCount == maxUnits) {
                    $('.PriceSliderDetails').hide();
                    $('.MaxTeamsMessage').show();
                } else {
                    $('.PriceSliderDetails').show();
                    $('.MaxTeamsMessage').hide();
                }

                rescaleXAxis();

                if (teamCount == 1 && !singleUnitChosen) {
                    $('.js-units').html(priceUnits.slice(0,-1));
                    singleUnitChosen = true;
                } else if (singleUnitChosen) {
                    singleUnitChosen = false;
                    $('.js-units').html(priceUnits);
                }
                $('#numberOfTeams').animateNumbers(teamCount);
                getCurrentPriceRange();
                $('#costPerTeam').animateNumbers(currentPriceRange.price_per_team);
                $('#costPerMonth').animateNumbers(parseInt(currentPriceRange.price_per_team) * teamCount);
                $('#teamDiscount').animateNumbers(calculateDiscount());
            };

            function showNoPricing () {
                $('.PriceSlider').hide();
                $('.PriceSliderDetails').hide();
                $('.MaxTeamsMessage').hide();
                $('.NoPricingMessage').show();
            };

            function getCurrentPriceRange () {
                sportPricingData.some(function (priceRange) {
                    if (parseInt(priceRange.max_team_count) >= teamCount) {
                        return !!(currentPriceRange = priceRange);
                    } else {
                        currentPriceRange = priceRange;
                    }
                });
            };

            function calculateDiscount () {
                if (!sportPricingData.length) return 0;
                return (parseInt(sportPricingData[0].price_per_team) * teamCount) - (parseInt(currentPriceRange.price_per_team) * teamCount);
            };

            function moveCounterToCenter () {
                var min = parseInt($('#sliderInput').attr('min')),
                    max = parseInt($('#sliderInput').attr('max')),
                    direction = teamCount < ((max + min)/ 2) ? '-' : '';

                teamCount = (max + min) / 2;
                // update slider info
                $('#sliderInput').val(teamCount);
                $('#sliderOutput').val(teamCount);
                if (!sportSelected) {
                    // only animate once
                    sportSelected = true;
                    $('.rangeslider-thumb').css({'transition': 'left 0.7s linear'});
                    $('.rangeslider-fill-lower').css({'transition': 'width 0.7s linear'});
                    /// add physics animation effect to teamCount Card
                    requestAnimationFrame(function () {
                        $('.range-output')[0].style.transform = 'rotate('+direction+'15deg)';
                    });
                }
                $('#sliderInput').rangeslider('update', true);
                setTimeout(function () {
                    $('.rangeslider-thumb').css({'transition': 'none'});
                    $('.rangeslider-fill-lower').css({'transition': 'none'});
                    requestAnimationFrame(function () {
                        $('.range-output')[0].style.transform = 'rotate(0deg)';
                    }.bind(this));
                }, 800);
            };


            function updatePriceUnits () {
                $('.js-units').html(priceUnits);
                $('.js-units-singular').html(priceUnits.slice(0,-1));
                if (priceUnits === 'athletes') {
                    $('#sliderInput').attr('max', 80).attr('min', 20).data('label-step', 10);
                    maxUnits = 80;
                } else {
                    $('#sliderInput').attr('max', 20).attr('min', 0).data('label-step', 5);;
                    maxUnits = 20;
                }
                $('.js-maxTeamsNumber').html(maxUnits);
                $('#sliderInput').rangeslider('update', true);
                rescaleXAxis();
            };


            function getSportPricingInfo(siteId) {
                $.get('/plans_pricing/sport_pricing_info?site_id='+siteId, function (data) {
                    if (data.status === 'success') {
                        // sort prices to later determine the current range
                         sportPricingData = data.data.sort(function (a, b) {
                             if (parseInt(a.max_team_count) > parseInt(b.max_team_count)) {
                                 return 1;
                             } else if (parseInt(a.max_team_count) < parseInt(b.max_team_count)) {
                                 return -1;
                             } else {
                                 return 0;
                             }
                         });
                         if ((parseInt(data.data[0].units_are_players) && priceUnits === 'teams') || (!parseInt(data.data[0].units_are_players) && priceUnits === 'athletes')) {
                             priceUnits = !!parseInt(data.data[0].units_are_players) ? 'athletes' : 'teams';
                             updatePriceUnits();
                             // move counter to average for new scale
                             moveCounterToCenter();
                             // set counter as average if this is the first sport picked
                         } else if (!sportSelected) {
                             moveCounterToCenter();
                         }
                        updateSportPrices();
                    } else {
                        showNoPricing();
                    }
                });
            };
            // animateNumbers was shamelessly stolen from this site https://detectify.com/pricing,
            $.fn.animateNumbers = function(stop, duration) {
                  return this.each(function() {
                      var $this = $(this);
                      var start = parseInt($this.text());
                      // create a jQuery object to take advantage of the step function within its animation function, an easy way to divide the difference between the start and end point.
                      $({value: start}).animate({value: stop}, {
                          duration: duration === undefined ? 500 : duration,
                          step: function() {
                              $this.text(Math.floor(this.value));
                          },
                          complete: function() {
                              $this.text(stop);
                        },
                    });
                });
            };

            function rescaleXAxis () {
                // the x axis is rerendered on window resize; use as hack to rerender rangeslider plugin
                $(window).trigger('resize');
            };
        },


        handleFlippingPricingCards: function () {
            // For the flipping cards; cf http://codepen.io/artemmedvedev/pen/mJMpdz
            checkScrolling($('.AthletePricingTable-body'));
        	$(window).on('resize', function() {
        		window.requestAnimationFrame(function(){checkScrolling($('.AthletePricingTable-body'))});
        	});
        	$('.AthletePricingTable-body').on('scroll', function() {
        		var selected = $(this);
        		window.requestAnimationFrame(function(){checkScrolling(selected)});
        	});

        	function checkScrolling(tables){
        		tables.each(function(){
        			var table= $(this),
        				totalTableWidth = parseInt(table.children('.AthletePricingTable-features').width()),
        		 		tableViewport = parseInt(table.width());
        			if( table.scrollLeft() >= totalTableWidth - tableViewport -1 ) {
        				// table.parent('li').addClass('is-ended');
        			} else {
        				// table.parent('li').removeClass('is-ended');
        			}
        		});
        	}

            //switch from monthly to annual pricing tables
            bouncy_filter($('.AthletePricingTable-container'));

            function bouncy_filter(container) {
                container.each(function(){
                    var pricing_table = $(this);
                    var filter_list_container = pricing_table.children('.AthletePricingTable-switcher'),
                        filter_radios = filter_list_container.find('input[type="radio"]'),
                        pricing_table_wrapper = pricing_table.find('.AthletePricingTable-wrapper');
                    //store pricing table items
                    var table_elements = {};
                    filter_radios.each(function(){
                        var filter_type = $(this).val();
                        table_elements[filter_type] = pricing_table_wrapper.find('li[data-type="'+filter_type+'"]');
                    });
                    //detect input change event
                    filter_radios.on('change', function(event){
                        event.preventDefault();
                        //detect which radio input item was checked
                        var selected_filter = $(event.target).val();
                        //give higher z-index to the pricing table items selected by the radio input
                        show_selected_items(table_elements[selected_filter]);
                        //rotate each AthletePricingTable-wrapper
                        //at the end of the animation hide the not-selected pricing tables and rotate back the .AthletePricingTable-wrapper
                        if( false && !Modernizr.cssanimations ) {
                            hide_not_selected_items(table_elements, selected_filter);
                            pricing_table_wrapper.removeClass('is-switched');
                        } else {
                            pricing_table_wrapper.addClass('is-switched').eq(0).one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function() {
                                hide_not_selected_items(table_elements, selected_filter);
                                pricing_table_wrapper.removeClass('is-switched');
                                //change rotation direction if .AthletePricingTable-list has the .cd-bounce-invert class
                                if(pricing_table.find('.AthletePricingTable-list').hasClass('cd-bounce-invert')) pricing_table_wrapper.toggleClass('reverse-animation');
                            });
                        }
                    });
                });
            }
            function show_selected_items(selected_elements) {
                selected_elements.addClass('is-selected');
            }

            function hide_not_selected_items(table_containers, filter) {
                $.each(table_containers, function(key, value){
                    if ( key != filter ) {
                        $(this).removeClass('is-visible is-selected').addClass('is-hidden');

                    } else {
                        $(this).addClass('is-visible').removeClass('is-hidden is-selected');
                    }
                });
            }
        },

        handleEventsPage : function () {
            var yOffset;
            $(document).on('change', '.ClubPricing-sportSelect', function () {
                var url = window.location.origin + '/events/';
                url += $('.js-EventPricing-sport').val().replace(/\s/gi, '').toLowerCase() + '/';
                url += $('.js-EventPricing-gender').val() + '/';
                url += $('.js-EventPricing-time').val();
                window.location.href = url;
            });
            // this.handleFlippingPricingCards();
            // if (window.location.hash === '#member') {
            //     $('#member_switch').prop('checked', true).siblings().prop('checked', false);
                // $('#memberList').show();
            // } else {
            //     $('#nonmember_switch').prop('checked', true).siblings().prop('checked', false);
                // $('#nonmemberList').show();
            // }
            $('.AthletePricingTable-switcher .fieldset').show();

            $(document).on('change',  '.AthletePricingTable-switcherEvents input', function (e) {
                yOffset = window.pageYOffset;
                window.addEventListener('scroll', stopScroll);
                window.location.hash = this.value;
                $('.AthletePricingTable-list').fadeToggle(200);
            });

            $(document).on('click', '.toggleCards', function () {
                yOffset = window.pageYOffset;
                window.addEventListener('scroll', stopScroll);
                window.location.hash = $(this).data('userType');
                $('#'+$(this).data('userType')).prop('checked', true).siblings().prop('checked', false);
                $('.AthletePricingTable-list').fadeToggle(200);
            });

            function stopScroll () {
                window.scrollTo(0, yOffset);
                window.removeEventListener('scroll', stopScroll);
                setTimeout(function () {
                }, 35);
            }
        },

        makeHoverAnimationSlides : function () {
            var $hoverSlides = $('.hover-slide'), idx = 0;
            var $hoverImages = $('.animated-hover-images .changing-image');
            var cycleActiveSlides = setInterval(function () {
                    $($hoverSlides[idx]).removeClass('is-active');
                    $($hoverImages[idx]).removeClass('is-active');
                    idx = (idx + 1) % 3;
                    $($hoverSlides[idx]).addClass('is-active');
                    $($hoverImages[idx]).addClass('is-active');
            }, 2000);

            $(document).on('mouseenter', '.hover-slide', function (e) {
                $(this).addClass('is-active').siblings().removeClass('is-active');
                $($hoverImages[$(this).data('index')]).addClass('is-active').siblings().removeClass('is-active');
                clearInterval(cycleActiveSlides);
            });
        },

        handleClubsPage: function () {
            this.makeHoverAnimationSlides();
            // transition time for the main element, or .DashedLine-main-element
            var mainAnimationDuration = 400;
            $('#ClubSolutions-CompleteOversight').waypoint(function () {
                animateDashedLines(0);
            }, {offset: '350px', triggerOnce: true});

            // This function calls itself until all of the dashed lines and the main elements to which they lead have been revealed; it uses indeces to show each group successively
            function animateDashedLines (containerIdx) {
                var lineIdx = 0;
                var dashedLines = $($('.DashedLine-container')[containerIdx]).find('.DashedLine');
                // Use an interval for the successive animations
                var showLines = setInterval(function () {
                    $(dashedLines[lineIdx]).addClass('is-visible');
                    lineIdx += 1;
                    if (lineIdx === dashedLines.length) {
                        clearInterval(showLines);
                        // allow some buffer time for the last dotted line to almost fully appear before loading the main element
                        setTimeout(function () {
                            $($('.DashedLine-main-element')[containerIdx]).addClass('is-visible');
                        }, 200)
                        // If there are more lines to animate, wait for the main-element to fully appear, briefly pause, and begin the next animation; else, salir;
                        if ($('.DashedLine-container')[containerIdx + 1]) {
                            setTimeout(function () {
                                    animateDashedLines(containerIdx + 1);
                            }, mainAnimationDuration + 200);
                        }
                    }
                }, 150);
            }
        },

        handleHighlightReels: function() {
            $(document).on('click', '[aria-expanded=true] ~ .dropdown-menu:not(.js-single-selection)', function(e) {
                e.stopPropagation();
            });

            $(document).on('click', '.sort-order [data-value]', function(e) {
                handleSingleOption.call(this, 'order');
            });

            $(document).on('click', '.sports-dropdown [data-value]', function (e) {
                handleSingleOption.call(this, 'sport');
            });

            function handleSingleOption (id) {
                var id = '#' + id;
                if ($(id).val() === this.dataset.value) {
                    $(id).val('');
                } else {
                    $(id).val($(this).data('value'));
                }
                $(this).parent().siblings().find('[data-value]').removeClass('checked');
                $(this).toggleClass('checked');
                updateSportPositions();
                $('.filter-settings input').trigger('change');
            }

            $(document).on('click', '.filterPill', function() {
                // Sport and Order can only have one selected option, and both dropdowns dont have elements with ids whose name matches its value
                var lookupId = this.text.replace(/\s+/g, '-');
                if ($('#' + lookupId).length === 0) {
                    $('#' + this.dataset.name).val('').parent().find('[data-value]').removeClass('checked');
                    if (this.dataset.name === 'sport') { updateSportPositions(); }
                } else {
                    $('#' + lookupId).prop('checked', false);
                }
                $('.filter-settings input').trigger('change');
            });

            $(document).on('click', '.HighlightFilter-appliedWrap .fa', function() {
                $(this).toggleClass('fa-plus-square-o fa-minus-square-o');
                $('.filter-settings').toggleClass('hidden-sm');
            });

            $(document).on('click', '.clear-all', function() {
                $('.filter-settings input').prop("checked", false);
                $('#order').val('');
                $('#sport').val('');
                $('.js-single-selection').children().find('[data-value]').removeClass('checked');
                updateSportPositions();
                $('.filter-settings input').trigger('change');
            });

            var totalOffset = 0;
            var totalNum = 18;

            handleButtonRemoval($('.video-container'));

            var clearElements = false;

            var options = {
                "positions": {
                    "offset": totalOffset,
                    "num": totalNum
                },
                // L'essentiel est invisible pour les yeux.
                "essential": {
                    "sport": [],
                    "gender": [],
                    "class_year": [],
                    "position": [],
                    "commit": [],
                    "order": []
                }
            };

            $(document).on('change', '.filter-settings input', SR.debounce(function() {
                options = {
                    "positions": {
                        "offset": totalOffset,
                        "num": totalNum
                    },
                    "essential": {
                        "sport": [],
                        "gender": [],
                        "class_year": [],
                        "position": [],
                        "commit": [],
                        "order": []
                    }
                };

                $('.filter-settings input').each(function(i, elem) {
                    if ($(this).prop('checked') || ($(this).val() !== '') && $(this).attr('type') == 'hidden') {
                        options.essential[$(this).attr('name')].push($(this).val());
                    }
                });

                SR.events.publish('/highlight-reel-filter/change', options);
                clearElements = true;
            }, 600));

            $('.load-more').on('click', function() {
                options.positions.offset += options.positions.num;
                clearElements = false;
                SR.events.publish('/highlight-reel-filter/change', options);
            });

            SR.events.subscribe('/highlight-reel-filter/change', function(options) {
                makeAnotherRequestForHighlightvideos(options);
                generateFilterBubbles(options);
            });

            function makeAnotherRequestForHighlightvideos(options) {
                $.ajax({
                    url: '/ajax/search_featured_videos',
                    type: 'POST',
                    data: {
                        data: {
                            offset: options.positions.offset,
                            num: options.positions.num,
                            params: JSON.stringify(options.essential)
                        }
                    },
                    dataType: 'json',
                    success: function (data){
                        if (clearElements) {
                            $('.video-container-wrapper').empty();
                        }
                        $.get('/js/templates/highlight-reels.mst', function(template) {
                            data.forEach(function(entry) {
                                // for weird spacing with comma's
                                if (entry.user !== undefined) {
                                    if (entry.user.sport_info.position && entry.user.sport_info.position.values && entry.user.sport_info.position.values.length > 1) {
                                        entry.user.sport_info.position.values = entry.user.sport_info.position.values.join(', &nbsp;');
                                    }
                                }
                                var rendered = Mustache.render(template, entry);
                                $('.video-container-wrapper').append(rendered);
                            });
                            // 18 is the query limit
                            handleButtonRemoval(data);
                        });
                    },
                    error: function(data) {
                        console.log(data);
                    }
                });
            }

            function generateFilterBubbles(options) {
                $('.active-filters').empty();

                $.each(options.essential, function(key, value) {
                    $.each(value, function(index, val) {
                        if (key === 'gender') {
                            if (val == 1) {
                                val = 'male';
                            } else if (val == 2) {
                                val = 'female';
                            }
                        }
                        $('.active-filters').append('<a class="filterPill" data-name="'+key+'">'+val+'<i class="fa fa-close"></i></a>');
                    });
                });
                if ($('.active-filters').children().length > 0) {
                    $('.active-filters').append('<a class="clear-all" id="clear-all">Clear All</a>');
                }
            }

            function handleButtonRemoval(videos) {
                if (videos.length < totalNum) {
                    $('.load-more').hide();
                } else {
                    $('.load-more').show();
                }
            }

            function updateSportPositions () {
                $.get('/ajax/get_sport_positions', {sport: $('#sport').val()}, function (data) {
                    var htmlString = '';
                    for (var position in data) {
                        if (data.hasOwnProperty(position)) {
                            var lookupId = position.replace(/\s+/g, '-');
                            htmlString += '<li class="col-xs-6 col-sm-4 col-md-12"><input type="checkbox" name="position" value="'+position+'" id="'+lookupId+'"/>' +
                                            '<label for="'+lookupId+'">'+position+'</label></li>';
                        }
                    };
                    if (htmlString.length) {
                        $('.positions-dropdown-toggle').removeClass('disabled');
                    } else {
                        $('.positions-dropdown-toggle').addClass('disabled');
                    }
                    $('.positions-dropdown').html(htmlString);
                });
            }
            // For closing out of video modals on mobile
            if (SR.browserIsMobile()) {
                var inTouchEvent = false;
                $(document).on('touchstart', 'body.modal-open', function (e) {
                    if ($(e.target).hasClass('modal-backdrop')) {
                        // set short timeout to determine whether user is swiping or tapping
                        inTouchEvent = true;
                        setTimeout(function () {
                            if (!inTouchEvent) {
                                $('#Modal_watch_video').modal('hide');
                            }
                        }, 150);
                    };
                });

                $(document).on('touchend', 'body.modal-open', function (e) {
                    inTouchEvent = false;
                });
            }
        },

        handleCampGuide: function () {
            if (window.location.hash === '#girls') {
                $('a[data-gender="girls"]').addClass('selected').siblings().removeClass('selected');
                $('#boys').hide();
                $('#girls').show();
            }
            $(document).on('click', '#toggleTabs a', function (e) {
                var $this = $(this);
                window.location.hash = $this.data('gender');
                $this.addClass('selected').siblings().removeClass('selected');
                $('#'+$this.data('gender')).siblings().fadeOut(400, function () {
                    $('#'+$this.data('gender')).fadeIn();
                });
            });
        },

        handleLoginForm: function () {
            $(document).on('click', '.login-request-magic-link', function () {
                var $form = $(this).closest('.login-form')
                requestMagicLink($form)
            })

            $(document).on('click', '.login-reset-password', function (e) {
                e.preventDefault()
                var $form = $(this).closest('.login-form')
                requestPasswordReset($form)
            })

            $(document).on('click', '.login-coach-set-password', function (e) {
                e.preventDefault()
                var $form = $(this).closest('.login-form')
                requestCoachSetPassword($form)
            })

            function requestMagicLink($form) {
                var email = $form.find('[name=email]').val()

                $form.find('.login-request-magic-link').hide()
                $form.find('.login-spinner').show()

                $.ajax({
                    url: API_URL + '/api/v1/magic/send',
                    type: 'POST',
                    dataType: 'json',
                    data: { email: email },
                    success: function(response, textStatus, xhr) {
                        $form.hide()
                        $form.siblings('.login-magic-link-success').fadeIn()
                    },
                    error: function(xhr, textStatus, error) {
                        if (xhr.status == 404) {
                            showError('The requested email address was not found.')
                        } else if (xhr.status == 429) {
                            showError("You've exceeded the maximum number of link requests. Please contact help@sportsrecruits.com for assistance.")
                        } else {
                            showError('An unexpected error occurred. Please try again later.')
                        }
                    },
                    complete: function(xhr, textStatus) {
                        $form.find('.login-request-magic-link').show()
                        $form.find('.login-spinner').hide()
                    }
                });
            }

            function requestPasswordReset($form) {
                var email = $form.find('[name=email]').val()

                $.ajax({
                    url: '/ajaxs/reset_password',
                    type: 'POST',
                    dataType: 'json',
                    data: { email: email },
                    success: function(response, textStatus, xhr) {
                        if (response.status === 'success') {
                            showAlert(response.message, 'success')
                        } else {
                            var message = response.message || 'An unexpected error occurred. Please try again later.'
                            showError(message)
                        }
                    },
                    error: function(xhr, textStatus, error) {
                        showError('An unexpected error occurred. Please try again later.')
                    }
                });
            }

            function requestCoachSetPassword($form) {
                var email = $form.find('[name=email]').val()

                $.ajax({
                    url: '/ajaxs/coach_set_password',
                    type: 'POST',
                    dataType: 'json',
                    data: { email: email },
                    success: function(response, textStatus, xhr) {
                        if (response.status === 'success') {
                            showAlert(response.message, 'success')
                        } else {
                            var message = response.message || 'An unexpected error occurred. Please try again later.'
                            showError(message)
                        }
                    },
                    error: function(xhr, textStatus, error) {
                        showError('An unexpected error occurred. Please try again later.')
                    }
                });
            }

            function showError(message) {
                showAlert(message, 'error')
            }

            function showAlert(message, type) {
                $('.alert-container').append(
                    $('<div class="alert">')
                        .addClass('alert-' + type)
                        .append('<button type="button" class="close" data-dismiss="alert">&times;</button>')
                        .append(message)
                )
            }
        },

        handleCommits: function () {
            var shownCommits = []
            var totalCommitCount = -1
            var currentPage = 0

            loadMore()

            $(document).on('click', '.loadmoreButton', function() {
                loadMore()
                return false
            })

            function loadMore() {
                var $loadButton = $('.loadmoreButton')

                currentPage++

                var url = '/ajax/otg_commits?page=' + currentPage

                var clubIdentifier = window.__clientData.clubIdentifier
                if (clubIdentifier) {
                    url += '&club=' + clubIdentifier
                }

                $loadButton.hide()
                showSpinner()

                return $.getJSON(url).then(handleCommitPayload).always(function() {
                    hideSpinner()
                    updateLoadButtonVisibility()
                })
            }

            function showSpinner() {
                $('.loadmoreSpinner').show()
            }

            function hideSpinner() {
                $('.loadmoreSpinner').hide()
            }

            function updateLoadButtonVisibility() {
                var $button = $('.loadmoreButton')
                $button.toggle(totalCommitCount === -1 || totalCommitCount > shownCommits.length)
            }

            function handleCommitPayload(payload) {
                shownCommits = shownCommits.concat(payload.commits)
                totalCommitCount = payload.count

                var $elements = renderCommitElements(payload.commits)
                insertCommitElements($elements)
            }

            function renderCommitElements(commits) {
                var template = $('#commitment-item-template').html()

                return commits.map(function(commit) {
                    commit.athlete.classYear = parseInt(commit.athlete.classYear)
                    var rendered = Mustache.render(template, { commit: commit })
                    return $(rendered)
                })
            }

            function insertCommitElements($commits) {
                var $container = $('.commitCardsRow')
                var $existingCommits = $container.find('.commitCards')

                if ($existingCommits.length) {
                    $existingCommits.last().after($commits)
                } else {
                    $container.prepend($commits)
                }
            }
        },
    };

    SROTG.init();
});
